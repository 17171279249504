<template>
    <div class="page">
        <div v-if="detailTitle||detailObj.title">
            <div class="bread-crumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item @click.native="change">{{detailTitle?detailTitle:detailObj.title}}</el-breadcrumb-item>
                    <el-breadcrumb-item>详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="box">
                <h2>{{detailObj.title}}</h2>
                <ul>
                    <li v-if="detailObj.author||detailObj.source">作者：{{detailObj.author?detailObj.author:(detailObj.source?detailObj.source:'来源')}}</li>
                    <li v-if="detailObj.createTime">发布时间：{{detailObj.createTime.substring(0,10)}}</li>
                    <li v-if="detailObj.lookNum||lookNum">浏览量：{{detailObj.lookNum?detailObj.lookNum:lookNum}}</li>
                </ul>
                <p v-if="detailObj.content" v-html="detailObj.content"></p>
                <div class="detail-img" v-else><img :src="detailObj.path" alt=""></div>
                <template v-if="detailObj.fileList">
                    <div v-for="item of detailObj.fileList" :key="item.id">
                        <a :href="item.address">{{item.name}}</a>
                    </div>
                </template>
<!-- <a v-if="detailObj.uploadFiles" :href="detailObj.uploadFiles">{{detailObj.uploadFiles}}</a> -->
</div>
</div>
<div v-else>
    <div class="list" v-for="(item) of conList" @click="toChange(item.type,item)" :key="item.id">
        <p>{{item.title}}</p>
        <ul>
            <li># {{item.author?item.author:(item.source?item.source:'来源')}}</li>
            <li>{{item.createTime.substring(0,10)}}</li>
        </ul>
    </div>
</div>
</div>
</template>
<script>
    export default {
        props: ['conList', 'tableList', 'type', 'detailObj', 'lookNum'],
        data() {
            return {
                detailTitle: '',
            }
        },
        created() {
            if (this.$route.query.detailTitle) {
                this.$parent.getDetail(this.$route.query.articleId);
            } else {
                this.$parent.change(1, 'list');
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if (val.query.articleId) this.$parent.getDetail(val.query.articleId);
                    else this.$parent.change(1, 'list');
                },
                // 深度观察监听
                deep: true
            }
        },
        methods: {
            toChange(type, item) {
                if (this.type == '10') {
                    this.detailTitle = item.title;
                } else {
                    if (type) {
                        this.detailTitle = this.tableList[type - 1];
                    } else {
                        this.detailTitle = this.tableList[this.type];
                    }
                }
                this.pageType = item.type;
                // 跳转当前列表详情页
                let path = location.href.split('#')[1];
                if (path.includes('?')) path = path.split('?')[0];
                let query = {};
                if (this.$route.query.id) query.id = this.$route.query.id;
                query.articleId = item.id;
                query.detailTitle = this.detailTitle;
                this.$router.push({
                    path: path,
                    query: query
                })

                // this.$parent.getDetail(item.id, item);
            },
            change() {
                this.detailTitle = '';
                // 跳转当前列表详情页
                let path = location.href.split('#')[1];
                if (path.includes('?')) path = path.split('?')[0];
                let query = {};
                if (this.$route.query.id) query.id = this.$route.query.id;
                this.$router.push({
                        path: path,
                        query: query
                    })
                    // this.$parent.change(1, 'list');
            },
        }
    }
</script>
<style scoped lang="scss">
    table {
        width: 100%;
        font-size: 16px;
        border: 1px solid #ddd;
        border-collapse: collapse;
    }
    
    table th {
        padding: 8px 0;
        box-sizing: border-box;
    }
    
    table td {
        border: 1px solid #ddd;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px 0;
        box-sizing: border-box;
    }
    
    @media screen and (max-width: 750px) {
        .box ::v-deep table {
            table-layout: fixed;
            width: 100% !important;
        }
        .box ::v-deep table td {
            width: auto !important;
        }
    }
    
    .bread-crumb {
        cursor: pointer;
    }
    
    .list {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        cursor: pointer;
        p {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            position: relative;
            padding-left: 15px;
            margin-bottom: 10px;
            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #050000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
            }
        }
        ul {
            display: flex;
            li:first-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 30px;
            }
            li:nth-child(2) {
                font-size: 14px;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #999999;
            }
        }
    }
    
    .box {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        h2 {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            text-align: center;
            margin-bottom: 10px;
        }
        ul {
            display: flex;
            justify-content: center;
            li {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 8px;
            }
            li:last-child {
                margin-right: 0;
            }
        }
        p {
            margin: 20px auto 0;
        }
    }
    
    .box p ::v-deep table {
        margin: 10px auto 0;
    }
    
    .detail-img img {
        display: block;
        margin: auto;
    }
</style>