<template>
    <div class="know">
        <HeaderPic :topId="topId" :topTitle="topText"></HeaderPic>
        <div class="know-main">
            <div class="know-left">
            <ul class="know-table-list">
                <li :class="{ active: ind == index }" @click="change(index)" v-for="(item,index) of tableList" :key="item">
                <img v-if="ind == index" src="../assets/img/know-school.png" alt=""><span>{{item}}</span>
                </li>
            </ul>
        </div>
        <div class="know-right">
          <div class="know-title">
            {{ind==-1?topText:tableList[ind]}}
            <img src="../assets/img/know-right-title.png" alt="">
          </div>
            <!-- 办事指南 -->
            <guide v-if="ind==0||ind==2||ind==3" ref="child" :type="ind" :detailObj="detailObj" :tableList="tableList" :conList="conList"></guide>
            <!-- 家长留言 -->
            <Parents v-if="ind==1" :detailList="detailObj" :parentList="parentList"></Parents>
            <el-pagination v-if="pageShow" :current-page.sync="pageNum" :page-size="pageSize" @current-change="handleCurrentChange" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </template>
<script>
    import HeaderPic from '../components/HeaderPic.vue'
    import guide from '../components/Information/NewIndex.vue'
    import Parents from '../components/Information/Parents.vue'
    import {
        workGuide,
        workGuideDetail,
        parentMessage, //家长留言
        parentMessageDetail,
        semesterHistory, //学期校历
        semesterHistoryDetail,
        educationStatute, //教育法规
        educationStatuteDetail,
        getPageViewByNameAndId
    } from "@/request/api";
    export default {
        components: {
            HeaderPic,
            guide,
            Parents
        },
        data() {
            return {
                topPicture: '',
                topText: '',
                topId: '',
                tableList: [],
                ind: 0,
                conList: [],
                parentList: [],
                detailObj: {},
                // 分页
                total: 0,
                pageNum: 1,
                pageSize: 10,
                pageShow: true
            }
        },
        created() {
            this.initTableList();
            this.workGuide();
        },
        mounted() {
            if (this.$route.query.id) {
                this.ind = this.$route.query.id - 1;
                this.change(this.ind);
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if (val.query.id) {
                        this.ind = this.$route.query.id - 1;
                        this.change(this.ind);
                    }

                    // this.partyBranch();
                },
                // 深度观察监听
                deep: true
            }
        },
        methods: {
            initTableList() {
                this.tableList = []
                for (let item of this.headerList[7].content) {
                    this.tableList.push(item.text)
                }
                this.topText = this.headerList[7].title;
                this.topId = this.headerList[7].id;
                console.log(this.headerList[7].content);
                // this.menuPic(this.headerList[7].id);
            },
            change(index, type) {
                if (!type) {
                    this.ind = index;
                    this.$refs.child.change();
                    if (this.ind == 0) this.workGuide();
                    if (this.ind == 1) this.parentMessage();
                    if (this.ind == 2) this.semesterHistory();
                    if (this.ind == 3) this.educationStatute();
                }
                this.detailObj = {};
                this.pageShow = true;
            },
            getDetail(id) {
                const _this = this;
                _this.pageShow = false;
                if (id) {
                    if (_this.ind == 0) this.workGuideDetail(id);
                    if (_this.ind == 1) this.parentMessageDetail(id);
                    if (_this.ind == 2) this.semesterHistoryDetail(id);
                    if (_this.ind == 3) this.educationStatuteDetail(id);
                }
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                if (this.ind == 0) this.workGuide();
                if (this.ind == 1) this.parentMessage();
                if (this.ind == 2) this.semesterHistory();
                if (this.ind == 3) this.educationStatute();
            },
            // 办事指南
            workGuide() {
                const _this = this;
                let data = {
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                workGuide(data).then(res => {
                    _this.conList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },

            workGuideDetail(id) {
                const _this = this;
                let data = {
                    id: id
                }
                workGuideDetail(data).then(res => {
                    _this.detailObj = res;
                    _this.getPageViewByNameAndId(id);
                    _this.pageShow = false;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            //获取当前页面浏览量
            getPageViewByNameAndId(id) {
                const _this = this;
                let data = {
                    pageName: _this.headerList[7].content[_this.ind].id,
                    pageId: id
                };
                getPageViewByNameAndId(data).then(res => {
                    _this.detailObj.lookNum = res.data;
                    console.log(_this.detailObj);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 家长留言
            parentMessage() {
                const _this = this;
                let data = {
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                parentMessage(data).then(res => {
                    _this.parentList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            parentMessageDetail(id) {
                const _this = this;
                let data = {
                    title: id
                }
                parentMessageDetail(data).then(res => {
                    _this.detailObj = res.replyContentVos;
                    console.log(_this.detailObj);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 学期校历
            semesterHistory() {
                const _this = this;
                let data = {
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                semesterHistory(data).then(res => {
                    _this.conList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            semesterHistoryDetail(id) {
                const _this = this;
                let data = {
                    id: id
                }
                semesterHistoryDetail(data).then(res => {
                    _this.detailObj = res;
                    _this.getPageViewByNameAndId(id);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 教育法规
            educationStatute() {
                const _this = this;
                let data = {
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                educationStatute(data).then(res => {
                    _this.conList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            educationStatuteDetail(id) {
                const _this = this;
                let data = {
                    id: id
                }
                educationStatuteDetail(data).then(res => {
                    _this.detailObj = res;
                    _this.getPageViewByNameAndId(id);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "../../public/css/frame.scss";
</style>