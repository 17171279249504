<template>
    <div class="page">
        <div class="leave-box" v-show="parentShow">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
                <el-form-item label="留言人" prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请输入..."></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input type="tel" placeholder="请输入..." v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="留言部门" prop="dept">
                    <el-select style="width: 70%;border: 1px solid #DCDFE6;border-radius: 4px;" v-model="ruleForm.dept" placeholder="请选择...">
                        <el-option v-for="item in options" :key="item.type" :label="item.type" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="主题" prop="title">
                    <el-input v-model="ruleForm.title" placeholder="请输入..."></el-input>
                </el-form-item>
                <el-form-item label="留言内容" prop="content">
                  <el-input style="width: 70%;" type="textarea" placeholder="请输入..." v-model="ruleForm.content"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="danger" @click="submitForm('ruleForm')">提交留言</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-show="!parentShow">
            <div class="leave-message">
                <img src="../../assets/img/edit.png" alt="">
                <span @click="toLeave">我要留言</span>
            </div>
            <div class="parents-list" @click="toggle(index,item.title)" v-for="(item,index) of parentList" :key="item.id">
                <p>留言：{{item.title}}</p>
                <span style="margin-bottom: 10px;display: block;">{{item.content}}</span>
                <span class="time-font">{{item.createTime}}</span>
                <div>
                    <div class="reply-style">
                        <div v-for="(ite,ind) of item.replyContentVos" :key="ind">
                            <!-- <p>{{ite.reply}}：<span>{{ite.reply}}</span></p> -->
                            <p>回复：<span>{{ite.reply}}</span></p>
                            <span class="time-font">{{ite.replyTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        deptSelect,
        insertMessage
    } from "@/request/api";
    export default {
        props: ['parentList', 'detailList'],
        data() {
            return {
                activeIndex: -1,
                parentShow: false,
                options: [],
                ruleForm: {
                    name: '',
                    phone: '',
                    dept: '',
                    content: '',
                    title: '',
                },
                rules: {
                    name: [{
                        required: true,
                        message: '请输入您的姓名',
                        trigger: 'blur'
                    }],
                    phone: [{
                        required: true,
                        message: '请输入您的电话',
                        trigger: 'blur'
                    }, {
                        min: 8,
                        max: 11,
                        message: '长度在 8 到 11 个字符',
                        trigger: 'blur'
                    }],
                    dept: [{
                        required: true,
                        message: '请选择留言部门',
                        trigger: 'change'
                    }],
                    title: [{
                        required: true,
                        message: '请填写留言主题',
                        trigger: 'blur'
                    }],
                    content: [{
                        required: true,
                        message: '请填写留言内容',
                        trigger: 'blur'
                    }]
                }

            }
        },
        methods: {
            toggle(index, id) {
                if (this.activeIndex == index) this.activeIndex = -1;
                else this.activeIndex = index;
                this.$parent.getDetail(id);
            },
            toLeave() {
                this.parentShow = true;
                this.deptSelect();
                this.$parent.getDetail();
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.insertMessage();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 部门集合
            deptSelect() {
                const _this = this;
                deptSelect().then(res => {
                    _this.options = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 添加留言
            insertMessage() {
                const _this = this;
                let data = {
                    name: _this.ruleForm.name,
                    phone: _this.ruleForm.phone,
                    dept: _this.ruleForm.dept,
                    content: _this.ruleForm.content,
                    title: _this.ruleForm.title,
                };
                insertMessage(data).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: '添加成功！',
                            type: 'success'
                        });
                        this.parentShow = false;
                    }
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .parents-list {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px 20px 20px 49px;
        box-sizing: border-box;
        margin-bottom: 20px;
        p {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            margin-left: -28px;
            margin-bottom: 10px;
        }
        span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
        span.time-font {
            font-size: 14px;
            font-family: Georgia;
            color: #999999;
            display: block;
        }
    }
    
    .reply-style {
        position: relative;
        margin-top: 20px;
    }
    
    .reply-style::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: #e2e2e2;
    }
    
    .reply-style p {
        padding-top: 20px;
    }
    
    .leave-message {
        width: 120px;
        cursor: pointer;
        /*  height: 36px; */
        background: #C5181F;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        padding: 17px 8px;
        box-sizing: border-box;
        img {
            margin-right: 5px;
        }
        span {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
        }
    }
    
    .leave-box {
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px 20px 20px 49px;
        box-sizing: border-box;
    }
    
    .el-input ::v-deep .el-input__inner {
        width: 70%;
        border: 1px solid #DCDFE6;
    }
</style>