import { render, staticRenderFns } from "./NewIndex.vue?vue&type=template&id=55329699&scoped=true&"
import script from "./NewIndex.vue?vue&type=script&lang=js&"
export * from "./NewIndex.vue?vue&type=script&lang=js&"
import style0 from "./NewIndex.vue?vue&type=style&index=0&id=55329699&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55329699",
  null
  
)

export default component.exports